<template>
    <div class="article-box">

        <div class="sub-article-box">
            <div class="article-title-box">

                <div class="article-title"><span>{{ curr_article.title }}</span></div>
                <div class="article-time"><span>发布日期：{{ curr_article.article_time }}</span></div>
            </div>



            <div class="article-content-box">
              <div style="max-width: 700px;padding: 0 32px 32px;margin: auto;">
                <img :src="`${$imgUrl}${curr_article.img_src}`" style="width: 100%">
              </div>

                <v-md-editor
                    v-model="curr_article.content"
                    mode="preview"
                ></v-md-editor>
            </div>

            <div class="share-box">
                <span class="share-title">分享到：</span>
                <span class="share-ico">
                    <el-popover
                        placement="top"
                        :width="100"
                        trigger="click">
                        <template #reference>
                          <span class="iconfont share-wx">&#xe601;</span>
                        </template>
                        <div id="qrCode" ref="qrCodeDiv"></div>
                    </el-popover>


                    <span class="iconfont" @click="shareQQ()">&#xe65b;</span>
                    <span class="iconfont" @click="shareWB()">&#xe6b3;</span>
                </span>

            </div>

            <div class="change-article-box">
                <div class="sub-change-box">
                    <div class="left-article-box public-article-box">
                        <a :href="pre_article.article_id? '/article/' + pre_article.article_id:'#'" class="left-a-box">
                            <div class="left-icon icon-sx el-icon-arrow-left"></div>
                            <div class="public-text-box">
                                <span class="public-text">上一篇</span>
                                <span class="public-title text-overflow" v-show="pre_article.article_id">{{ pre_article.title }}</span>
                                <span class="public-title text-overflow" v-show="!pre_article.article_id">没有了</span>
                            </div>
                        </a>
                    </div>
                    <br class="is-show">

                    <div class="right-article-box public-article-box">
                        <a :href="next_article.article_id? '/article/' + next_article.article_id:'#'" class="right-a-box">
                            <div class="right-icon icon-sx el-icon-arrow-right"></div>
                            <div class="public-text-box right-text-box">
                                <span class="public-text">下一篇</span>
                                <span class="public-title text-overflow" v-show="next_article.article_id">{{ next_article.title }}</span>
                                <span class="public-title text-overflow" v-show="!next_article.article_id">没有了</span>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import NativeShare from 'nativeshare'
import QRCode from 'qrcodejs2';
import {ElMessage} from "element-plus";
export default {
    name: "Article",
    data() {
        return {
            curr_article: {},
            pre_article: {},
            next_article: {},
        }
    },
    created() {
        var that = this;
        that.axios.get('/article/' + that.$route.params.id)
            .then(function (response) {
                var res_data = response.data
                that.curr_article = res_data.curr_article;
                that.pre_article = res_data.pre_article;
                that.next_article = res_data.next_article;

                document.title=that.curr_article.title;
            })
            .catch(function (error) {
                ElMessage({
                    showClose: true,
                    message: '文章内容获取失败，请刷新',
                    type: 'error'
                });
            })

    },
    methods: {
        bindQRCode: function () {
            new QRCode(this.$refs.qrCodeDiv, {
                text: document.location.href,
                width: 200,
                height: 200,
                colorDark: "#333333", //二维码颜色
                colorLight: "#ffffff", //二维码背景色
                correctLevel: QRCode.CorrectLevel.L//容错率，L/M/H
            })
        },
        shareQQ: function () {
            window.open('https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=' + document.location.href + '&sharesource=qzone&title=' + this.article_title + '&pics=' + this.article_img + '&desc=你的分享描述');
        },
        shareWB: function () {
            window.open('http://service.weibo.com/share/share.php?url=' + document.location.href + '&sharesource=weibo&title=' + this.article_title + '&pic=' + this.article_img + '&appkey=');
        },

        openCopy() {
            ElMessage.success({
                message: '复制成功',
                type: 'success'
            });
        },

    },
    mounted: function () {
        this.$nextTick(function () {
            this.bindQRCode();
        });

        // let btn_list = document.getElementsByClassName('v-md-copy-code-btn');
        // console.log(btn_list)
        // let i;
        // for(i in btn_list)
        // {
        //     console.log(btn_list)
        //     console.log(btn_list[i])
        //     btn_list[i].onclick=this.openCopy;
        //     btn_list[i].onclick=this.openCopy;
        // }


    },


}
</script>

<style scoped>
    .article-box{
        padding: 60px 40px;
        margin: 0 auto;
        -webkit-user-select: text!important; /* Safari */
        -ms-user-select: text!important; /* IE 10+ and Edge */
        user-select: text!important; /* Standard syntax */
        -moz-user-select: text!important;

    }
    .sub-article-box {
        max-width: 950px;
        width: 100%;
        margin: 0 auto;
    }

    .article-title-box{
        padding: 20px 0;
    }
    .article-title{
        font-size:22px;
        font-weight:bold;
        color:#333333;
        text-align: center;
    }
    .article-time {
        margin-top: 20px;
        font-size: 13px;
        color: #999999;
        text-align: center;
    }


    .article-content-box{
        padding-top: 30px;
        padding-bottom: 60px;
        font-size: 15px;
        line-height: 26px;
        color: #666666;
        border-bottom: 1px solid rgba(232,232,232,1);
    }


    .share-box{
        padding-top: 20px;
    }
    .share-title{
        font-size: 16px;
    }
    .share-ico span{
        font-size: 20px;
        margin-left: 13px;
    }

    .share-ico span:hover{
        cursor: pointer;
    }


    /*md编辑器自带的 内边距*/
    .github-markdown-body {
        padding: 0!important;
    }





    @media screen and (min-width: 0px) and (max-width: 600px) {

        .article-box {
            padding: 60px 15px;
        }
        .share-wx{
            display: none;
        }
    }
</style>

<!--上一章下一章-->
<style>
    .change-article-box{
        padding: 40px 0;
    }
    .sub-change-box{
        width: 100%;
        display: table;
    }
    .sub-change-box a{
        color: #999999;
    }
    .sub-change-box a:hover{
        color: black;
    }

    .public-article-box{
        display: table-cell;
        vertical-align: middle;
        padding-top: 10px;
        min-width: 300px;
    }
    .icon-sx{
        display: table-cell;
        vertical-align: middle;
        height: 50px;
        font-size: 50px;
    }


    .left-a-box{
        float: left;

    }
    .right-icon{
        float: right;
    }
    .left-icon{
        float: left;
    }
    .public-text-box{
        display: table-cell;
        vertical-align: middle;
        height: 50px;
    }


    .public-text{
        display: block;
        white-space: nowrap;
    }
    .public-title{
        white-space: nowrap;
        padding-top: 10px;
        display: block;
        max-width: 250px;
    }



    @media screen and (min-width: 990px) and (max-width: 9999px) {
        .is-show{
            display: none;
        }
        .right-a-box{
            float: right;
            /*text-align: right;*/
        }
    }


    @media screen and (min-width: 0px) and (max-width: 990px) {
        .right-icon{
            float: left;
        }

        .sub-change-box{
            display: block!important;
        }
    }

</style>

<!--分享模块-->
<style>
    .el-popover{
        min-width: 10px!important;
        min-height: 10px!important;
    }
    #qrCode img{
        width: 100%;
    }



</style>
